import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
     <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSezLg7ERGFDlOp5YJMwH3clJtR0xqRhX84RZB6sucPqbW7jyQ/viewform?embedded=true" width="640" height="1073" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  );
}

export default App;
